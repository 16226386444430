
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PercentFilter from '@/modules/common/filters/percent.filter';
import type Day from '@/modules/common/types/day.type';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import type HelperService from '@/modules/common/services/helper.service';
import RankingDocumentItem from '@/modules/ranking/models/ranking-document-item.model';

@Component({
    filters: { PercentFilter, PriceFilter },
})
export default class CommonRankingBreakdown extends Vue {
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.UserService) private userService!: UserService;

    @Prop({
        type: String,
    })
    private providerId!: string;

    @Prop({
        type: Number,
    })
    private currentHotelId!: number;

    @Prop({
        type: Boolean,
    })
    private skeleton!: boolean;

    @Prop({
        type: Array,
        default: null,
    })
    private hotels!: RankingDocumentItem[] | null;

    get compsetType(): COMPSET_TYPE {
        return COMPSET_TYPE.MEDIAN;
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    validateLink(link: string) {
        return /^http/.test(link) ? link : `https://${link}`;
    }

    triggerClose(): void {
        this.$router.back();
    }
}
