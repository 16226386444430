
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import DemandOccupancyGraph from '@/modules/common/components/ui-kit/occupancy-demand-graph/index.vue';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';

@Component({
    components: {
        DemandOccupancyGraph,
    },
})
export default class DemandGraphComponent extends Vue {
    @inject(HotelHomepageServiceS) protected hotelHomepageService!: HotelHomepageService;

    get demandData() {
        return this.hotelHomepageService.demandsData || [];
    }

    get demandLabels() {
        return this.hotelHomepageService.demandsLabels;
    }

    get isLoading() {
        const { demandsData } = this.hotelHomepageService;

        return demandsData === null || this.hotelHomepageService.isDemandDataLoading;
    }

    public dummyData = Array(30).fill(0);
}
