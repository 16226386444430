
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import CustomDesignSelect, { Item } from '@/modules/common/components/ui-kit/custom-design-select.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import type CompsetsService from '@/modules/compsets/compsets.service';

@Component({
    components: { CustomDesignSelect },
})
export default class ProviderHomeFilter extends Vue {
    @inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get provider() {
        return this.homeFiltersService.storeState.settings.visibilityProvider;
    }
    set provider(value) {
        const { settings } = this.homeFiltersService.storeState;
        this.homeFiltersService.saveFilters({ ...settings, visibilityProvider: value });
    }

    getProviderLabel(providerName: string) {
        const { allProviders } = this.providersService;

        if (!allProviders) {
            return providerName;
        }

        const provider = allProviders[providerName];

        if (!provider) {
            return '';
        }

        return provider.label || '';
    }

    get providerItems(): Item[] {
        const compset = this.compsetsService.getCompset(this.homeFiltersService.storeState.settings.visibilityCompsetId);

        if (!compset) {
            return [];
        }

        return compset.marketProviders.map(provider => ({
            value: provider,
            name: this.getProviderLabel(provider) as string,
        }));
    }
}
