
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomDesignSelect from '@/modules/common/components/ui-kit/custom-design-select.vue';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import type ProvidersService from '@/modules/providers/providers.service';

@Component({
    components: { CustomDesignSelect },
})
export default class ProviderRatesFilter extends Vue {
    @inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get provider(): string | null {
        return this.homeFiltersService.ratesProvider;
    }

    set provider(value: string | null) {
        if (!value) return;
        this.homeFiltersService.updateProvider(value);
    }

    getProviderLabel(providerName: string) {
        return this.providersService.allProviders[providerName]?.label;
    }

    get providerItems() {
        const { allProviders } = this.providersService;

        return this.homeFiltersService.possibleProviders
            .map(name => ({
                value: name,
                name: allProviders ? (allProviders[name].label || name) : name,
            }));
    }
}
