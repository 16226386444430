import { render, staticRenderFns } from "./guest-reviews-container.vue?vue&type=template&id=5ed7ce2d&scoped=true&"
import script from "./guest-reviews-container.vue?vue&type=script&lang=ts&"
export * from "./guest-reviews-container.vue?vue&type=script&lang=ts&"
import style0 from "./guest-reviews-container.vue?vue&type=style&index=0&id=5ed7ce2d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed7ce2d",
  null
  
)

export default component.exports