
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import { Vue, Component } from 'vue-property-decorator';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';

@Component
export default class RatesFiltersTooltip extends Vue {
    @inject(KEY.SettingsGeneralService)
    private settingsGeneralService!: SettingsGeneralService;

    @inject(MealTypesServiceS)
    private mealTypesService!: MealTypesService;

    get filtersLabels() {
        const { numberOfGuests, price } = this.settingsGeneralService.defaultFilters;
        const { los, mealType } = this.settingsGeneralService.defaultFilters;

        return {
            [this.$tc('titles.numberOfGuests')]: numberOfGuests,
            [this.$tc('titles.priceType')]: this.$tc(`price.${price}`),
            [this.$tc('titles.mealType')]: this.mealTypesService.getMealType(mealType)?.displayName,
            [this.$tc('titles.roomType')]: this.$tc('filters.any'),
            [this.$tc('titles.los')]: los,
        };
    }
}
