
import { Component, Vue } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import RankingProviders from '@/modules/ranking/components/ranking-providers.vue';
import ToUppercase from '@/modules/common/filters/to-uppercase.filter';
import type UserService from '@/modules/user/user.service';
import { inject } from '@/inversify';

@Component({
    components: {
        CompsetDocumentFilter,
        RankingProviders,
    },
    filters: { ToUppercase },
})
export default class GuestReviewsContainer extends Vue {
    @inject(KEY.UserService) protected userService!: UserService;

    get reviewsRoute() {
        const { userService } = this;

        return {
            name: `${userService.viewAs}.ranking`,
            params: {
                hotelId: userService.currentHotelId,
                userType: userService.viewAs,
            },
        };
    }
}
