
import { ChartData, ChartOptions, ChartDataSets } from 'chart.js';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type HelperService from '@/modules/common/services/helper.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import type HomeService from '@/modules/home/home.service';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import type UserService from '@/modules/user/user.service';
import type HotelsService from '@/modules/hotels/hotels.service';

import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import PosRatesFilter from '@/modules/home/components/rates/pos-rates-filter.vue';
import ProviderRatesFilter from '@/modules/home/components/rates/provider-rates-filter.vue';
import RatesCompsetChain from '@/modules/home/components/rates/rates-compset-chain.vue';
import RatesGraphCompsetLabel from '@/modules/rates/components/graph/rates-graph-compset-label.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import GraphChanger from '@/modules/common/components/ui-kit/graph-chain-toggle.vue';
import RatesGraphTooltipContainer from '@/modules/home/components/rates/rates-graph-tooltip-container.vue';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import RatesFiltersTooltip from './rates-filters-tooltip.vue';

@Component({
    components: {
        ProviderRatesFilter,
        CustomGraph,
        RatesGraphCompsetLabel,
        GraphChanger,
        RatesCompsetChain,
        LoaderWrapper,
        RatesGraphTooltipContainer,
        PosRatesFilter,
    },
})
export default class RatesContainer extends Vue {
    @inject(KEY.HomeService) homeService!: HomeService;
    @inject(KEY.SettingsGeneralService) settingsGeneralService!: SettingsGeneralService;
    @inject(HotelHomepageServiceS) private hotelHomepageService!: HotelHomepageService;
    @inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.HelperService) helperService!: HelperService;

    isLineGraph: boolean = true;

    private currencySymbol: string | null = null;
    RatesFiltersTooltip = RatesFiltersTooltip;

    beforeMount() {
        this.homeService.storeState.loadingRates.reset();
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }

    get skeleton() {
        return this.homeService.storeState.loadingRates.isLoading();
    }

    get chartData(): ChartData {
        const medianCompsetRange = this.homeService.compsetMinMaxPrices(COMPSET_TYPE.MEDIAN);
        const lowCompsetRange = this.homeService.compsetMinMaxPrices(COMPSET_TYPE.LOW);
        const highCompsetRange = this.homeService.compsetMinMaxPrices(COMPSET_TYPE.HIGH);
        const currency = this.homeService.getRatesCurrency(this.homeFiltersService.ratesPos);
        this.currencySymbol = this.helperService.currencySymbol(currency);

        const datasets: ChartDataSets[] = [];
        const labels: Date[] = this.hotelHomepageService.ratesLabels;

        datasets.push({
            borderDash: [6, 8],
            data: lowCompsetRange ? lowCompsetRange.maxPrices : [],
            borderColor: '#FFB34E',
            borderWidth: 1,
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            data: lowCompsetRange ? lowCompsetRange.minPrices : [],
            borderDash: [0, 1],
            fill: 0,
            backgroundColor: 'rgba(255, 179, 78, 0.3)',
            borderColor: 'black',
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            borderDash: [6, 8],
            data: highCompsetRange ? highCompsetRange.minPrices : [],
            borderColor: '#C8102E',
            borderWidth: 1,
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            data: highCompsetRange ? highCompsetRange.maxPrices : [],
            borderDash: [0, 1],
            backgroundColor: 'rgba(200, 16, 46, 0.3)',
            borderColor: 'black',
            fill: 2,
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            borderDash: [8, 8],
            data: this.homeService.getMedianCompsetsAveragePrices(),
            borderColor: '#79CFF3',
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            data: medianCompsetRange ? medianCompsetRange.minPrices : [],
            borderDash: [0, 1],
            lineTension: 0,
            borderColor: 'black',
            borderWidth: 1,
            borderJoinStyle: 'round',
        });

        datasets.push({
            data: medianCompsetRange ? medianCompsetRange.maxPrices : [],
            borderDash: [0, 1],
            backgroundColor: 'rgba(121, 207, 243, 0.3)',
            borderColor: 'black',
            borderWidth: 1,
            fill: 5,
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        datasets.push({
            data: this.homeService.getMyPricesAverage(),
            borderColor: '#00759E',
            lineTension: 0,
            borderJoinStyle: 'round',
        });

        return { labels, datasets };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        // TODO: update maxTicksLimit plugin to drow horizontal line
                        maxTicksLimit: 5, /* we have plugin, that add last sixth tick  */
                        // eslint-disable-next-line consistent-return
                        callback: value => {
                            const date = new Date(value);
                            const day = date.getDate();
                            const month = date.getMonth();
                            return `${day}/${month + 1}`;
                        },
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        // min: 0, // TODO: count min
                        // max: 130, // TODO: count max
                        maxTicksLimit: 3,
                        autoSkip: true,
                        callback: value => `${this.currencySymbol || ''} ${this.currencySymbol ? value : ''}`,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    get ratesRoute() {
        const { userService } = this;

        return {
            name: `${userService.viewAs}.rates`,
            params: { hotelId: userService.currentHotelId },
        };
    }
}
