
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
import ScoreTooltipWrapper from './score-tooltip-wrapper.vue';
import SCORE_THRESHOLDS from '../constants/score-thresholds.constant';

@Component({
    components: {
        ScoreTooltipWrapper,
        LoaderWrapper,
    },
})
export default class CiScore extends Vue {
    @inject(ScoreServiceS) scoreService!: ScoreService;

    ScoreTooltipWrapper = ScoreTooltipWrapper;

    get score() {
        if (!this.scoreService.data.document) {
            return null;
        }
        return this.scoreService.data.document.score;
    }

    get skeleton() {
        return this.scoreService.storeState.loading.isLoading();
    }

    get assessment() {
        return this.scoreService.scoreAssessment && this.scoreService.scoreAssessment.toLowerCase();
    }

    get assessmentColor() {
        if (!this.scoreService.scoreAssessment) {
            return null;
        }

        const threshold = this.scoreService.scoreAssessment;
        const thresholdEntries = Object.entries(SCORE_THRESHOLDS);
        const colorIndex = thresholdEntries.slice(0, thresholdEntries.length / 2).reverse().findIndex(([_, value]) => threshold === value);

        if (colorIndex === -1) {
            return null;
        }

        return this.scoreService.thresholdColors[colorIndex];
    }

    get displayScoreDif() {
        if (this.scoreService.scoreDif === null) {
            return null;
        }
        return `(${this.scoreService.scoreDif > 0 ? '+' : ''}${this.scoreService.scoreDif})`;
    }
}
