
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type RankingService from '@/modules/ranking/ranking.service';
import type UserService from '@/modules/user/user.service';
import CommonRankingBreakdown from '@/modules/common/modules/ranking/components/ranking-breakdown.common.vue';
import type HotelsService from '@/modules/hotels/hotels.service';

@Component({
    components: { CommonRankingBreakdown },
})
export default class RankingBreakdown extends Vue {
    @inject(KEY.RankingService) private rankingService!: RankingService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    @Prop({ type: String })
    private providerId!: string;

    @Prop({ type: Boolean })
    private skeleton!: boolean;

    get hotels() {
        const hotels = this.rankingService.getAllHotels(this.providerId);

        if (!hotels) {
            return null;
        }

        const keys = Object.keys(hotels);

        if (!keys || !keys.length) {
            return null;
        }

        const tableData = keys.map((key: string) => ({
            ...hotels![Number(key)],
            hotelId: Number(key),
            hotelName: hotels![Number(key)].hotelName || this.hotelsService.hotelNames[Number(key)],
        }));
        tableData.sort((a, b) => b.reviews - a.reviews);
        tableData.sort((a, b) => b.rating - a.rating);

        return tableData;
    }

    get myHotelId(): number | null {
        return this.userService.currentHotelId;
    }
}
